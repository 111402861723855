<template>
  <div class="about">
    <div class="zxkw">
      <p class="title">关于我们</p>
      <div class="zxkw-content">
        <p>
          <span>中新宽维传媒科技有限公司</span>
          是中国新闻出版传媒集团旗下企业，2021年注册于中国（上海）自由贸易区临港新片区，公司通过运用大数据、人工智能、区块链等技术，建设国家级文化大数据平台，服务国家海外传播和文化走出去战略，开展国际传播和文化出海，专注于数字贸易创新发展，与全球头部直播平台合作，建设高质量的跨境直播生态系统，服务全国跨境贸易企业，提供一站式直播出海网络通道及大数据服务。
        </p>
        <p>
          2021年3月，中新宽维与中国（上海）自由贸易区临港新片区管委会签署深度合作协议，在跨境数据区域内建设“中宽维数字港”，打造国际传播与文化出海领域的跨境服务平台。
        </p>
        <p>
          2021年12月，中新宽维与中国（上海）自由贸易区临港新片区管委会签署战略合作框架协议，在临港新片区开展国际传播、文化出海、数字贸易、大数据研究等合作内容。
        </p>
        <p>
          2022年7月，中新宽维与临港科技城与上海临港新片区信息飞鱼经济发展有限公司、上海临港新片区跨境数据科技有限公司签署战略合作框架协议，三方在跨境数据流通、跨境直播贸易生态链等领域开展深入合作。
        </p>
        <p>
          2022年10月，临港新片区首款服务跨境直播的网络服务产品“e路播”正式上线，为出海企业、跨境直播间提供一站式网络通道和大数据服务，跨境出海不再“灰色”。
        </p>
      </div>
    </div>

    <!-- <div class="xxfy-kjsk">
      <div class="xxfy-kjsk-content">
        <div class="xxfy-content">
          <p>
            <span>上海临港新片区信息飞鱼经济发展有限公司</span>
            是上海临港经济发展（集团）有限公司的二级平台公司，由上海临港经济发展（集团）有限公司和上海临港控股股份有限公司共同出资设立，旨在重点聚焦国际创新协同区信息飞鱼数字经济特色园区的开发建设，围绕国际数据跨境流通的特殊功能，依托跨境数据中心、新型互联网交换中心为枢纽、跨境数据流通公共服务平台为窗口等基础设施和功能平台，定位数字经济相关产业，重点发展云计算、通信技术、智能网联汽车、金融科技等产业。
          </p>
        </div>
        <div class="kjsk-content">
          <p>
            <span>上海临港新片区跨境数据科技有限公司</span>
            上海临港新片区跨境数据科技有限公司
            是由上海联和投资有限公司、上海市信息投资股份有限公司与上海
            临港科技创新城经济发展有限公司合资设立的子公司，重点聚焦
            “国际数据港”项目建设，围绕国际数据跨境流通的目标，构建以
            跨境数据流通功能性基础设施为承载、新型互联网交换中心为枢纽、
            跨境数据流通公共服务平台为窗口“三位一体”的国际信息通信基
            础设施，成为跨境数据流通领域领先的创新推动者、价值发掘者、
            生态建设者，推动国际数据港建设，使跨境数据流通更加安全有序、
            便捷高效。
          </p>
        </div>
      </div>
    </div> -->

    <div class="cooperation">
      <p class="cooperation-title">深度合作</p>
      <div class="cooperation-content">
        <p>
          中新宽维与临港新片区共建国际数据港跨境直播基地，共同在临港新片区建设符合国际标准的数字基础设施，汇聚全球数字技术的产业布局，建设友好、开放、稳定的数字贸易规则体系。同步引入头部跨境直播网络平台，吸引优质的跨境直播间入驻，共同打造临港新片区国际数据港数字贸易新业态，服务中国出海企业、MCN机构、跨境直播间开辟广阔的海外市场和新增长点。
        </p>
      </div>
      <div class="nums">
        <div class="item">
          <p>推动<span>10个</span>标志性企业率先</p>
          <p>实现跨境数据流通试点</p>
        </div>
        <div class="item">
          <p>汇聚<span>100家</span>数据及智</p>
          <p>能领域的头部企业</p>
        </div>
        <div class="item">
          <p>带动引领<span>100个</span>跨境数</p>
          <p>据交互的示范应用项目实施</p>
        </div>
        <div class="item">
          <p>数字产业规模突破</p>
          <p><span>1000亿</span></p>
        </div>
      </div>
    </div>

    <div class="digital-port">
      <p>中新宽维数字港</p>
      <img src="@/assets/images/about/zxkw.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.about {
  flex: 1;
  margin-top: -24px;
  margin-bottom: -24px;

  .zxkw {
    width: 100%;
    height: 800px;
    background: url("../../assets/images/about/bg1.jpg") center no-repeat;
    background-size: cover;
    padding-top: 94px;
    text-align: center;
    .title {
      color: #ffffff;
      font-size: 44px;
      font-weight: bold;
      margin-bottom: 34px;
    }
    .zxkw-content {
      width: 1440px;
      padding: 56px 60px 56px 60px;
      border: 1px solid #ffffff;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      margin: 0 auto;
      text-align: left;
      p {
        font-size: 24px;
        color: #ffffff;
        line-height: 36px;
        text-indent: 2em;
        margin-bottom: 36px;
        margin-bottom: 0;
        span {
          font-size: 32px;
          color: #ff9c00;
        }
      }
    }
  }

  .xxfy-kjsk {
    width: 100%;
    height: 750px;
    background: url("../../assets/images/about/bg3.jpg") center no-repeat;
    background-size: cover;
    padding-top: 150px;
    .xxfy-kjsk-content {
      width: 1440px;
      height: 500px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
    .xxfy-content,
    .kjsk-content {
      width: 710px;
      height: 100%;
      border: 1px solid #ffffff;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      padding: 49px 38px 0 39px;
      p {
        font-size: 24px;
        color: #ffffff;
        line-height: 24px;
        text-indent: 2em;
        margin-bottom: 0;
        line-height: 36px;
        span {
          font-size: 32px;
          color: #ff9c00;
        }
      }
    }
  }

  .cooperation {
    width: 100%;
    height: 696px;
    background: url("../../assets/images/about/bg4.jpg") center no-repeat;
    background-size: cover;
    padding-top: 89px;
    .cooperation-title {
      color: #242b55;
      font-size: 44px;
      text-align: center;
      margin-bottom: 59px;
    }
    .cooperation-content {
      width: 1440px;
      padding: 50px 75px 29px 75px;
      background-color: rgba(255, 255, 255, 0.5);
      margin: 0 auto 30px auto;
      border-radius: 9px;
      p {
        font-size: 24px;
        color: #242b55;
        line-height: 36px;
        text-indent: 2em;
        text-align: justify;
      }
    }
    .nums {
      width: 1440px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .item {
        width: 340px;
        height: 160px;
        background-color: #ffffff;
        border-radius: 10px;
        padding-top: 35px;
        p {
          margin: 0;
          text-align: center;
          color: #242b55;
          font-size: 24px;
          line-height: 36px;
          span {
            color: #fc8e00;
            font-size: 44px;
          }
        }
      }
    }
  }

  .digital-port {
    width: 100%;
    height: 1019px;
    background: url("../../assets/images/about/bg5.jpg") center no-repeat;
    background-size: cover;
    padding-top: 125px;
    p {
      color: #242b55;
      font-size: 44px;
      text-align: center;
      margin-bottom: 72px;
    }
    img {
      width: 1440px;
      height: 720px;
      object-fit: cover;
      margin: 0 auto;
    }
  }
}
</style>
